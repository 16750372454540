/* eslint-disable @next/next/no-page-custom-font */
import '@/styles/globals.css';
import 'react-toastify/dist/ReactToastify.min.css';

import ApolloClientProvider from '@/context/ApolloClientProvider';
import AuthProvider from '@/context/AuthProvider';
import { AntdRegistry } from '@ant-design/nextjs-registry';
import { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ApolloClientProvider>
      <AntdRegistry>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </AntdRegistry>
      <ToastContainer />
    </ApolloClientProvider>
  );
}
