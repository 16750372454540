'use client';
import { get_token } from '@/utils/cookie-store';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { FC } from 'react';

export const GQLClient = () => {
  const token = get_token();

  const headers = {
    'content-type': 'application/json',
    'x-hasura-admin-secret': process.env.NEXT_PUBLIC_ADMIN_KEY,
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const httpLink = new HttpLink({
    headers,
    uri: process.env.NEXT_PUBLIC_BASE_URL,
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache(),
    link: httpLink,
  });
};

interface Props {
  children: any;
}

const ApolloClientProvider: FC<Props> = ({ children }) => {
  const client = GQLClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
