import { is_logged_in } from '@/utils/cookie-store';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const AuthProvider = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    if (!is_logged_in() && router.pathname !== '/login') {
      router.push('/login');
    }
  }, [router]);

  return children;
};

export default AuthProvider;
