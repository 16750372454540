// @ts-nocheck
import Cookie from 'js-cookie';
import SimpleCrypto from 'simple-crypto-js';

const crypto = new SimpleCrypto(process.env.NEXT_PUBLIC_CRYPT_SECRET);
const storage_key = 'FOODBAY';

export const save_token = (token) => {
  try {
    Cookie.set(storage_key + '_TOKEN', crypto.encrypt(token));
  } catch (e) {
    throw new Error('Could not save token to cookie store');
  }
};

export const get_token = () => {
  try {
    const token = Cookie.get(storage_key + '_TOKEN');
    if (!token) return null;
    return crypto.decrypt(token);
  } catch (e) {
    throw new Error('Could not fetch user object from cookie store');
  }
};

export const remove_token = () => Cookie.remove(storage_key + '_TOKEN');

export const clear_store = () => {
  try {
    remove_token();
  } catch (e) {
    throw new Error('Could not delete user object from cookie store');
  }
};

export const is_logged_in = () => get_token() !== null;
